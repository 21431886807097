import { FC, PropsWithChildren, createContext, useContext, useReducer } from 'react';

import { AdyenPaymentResultCode } from '@/models/AdyenPaymentResultCode.enum';
import { AuthorizationDialog } from '@/components/_overlays/AuthorizationDialog';
import { EntitlementsNode } from '@/models/ISubscription.interface';
import { IOfferIds } from '@/models/ILandingPage.interface';
import { IOfferItem } from '@/models/IOffer.interface';
import { IPayment } from '@/models/IPaymentHistory.interface';
import { OfferType } from '@/models/OfferTypeName.enum';
import { PaymentType } from '@/models/Payment.enum';
import { SubscriptionProvider } from './subscription/subscription.provider';
import { useMountEffect } from '@/hooks/useMountEffect';
import { useRouter } from 'next/router';

export const enum AuthorizationSteps {
  AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  BUY_OFFER,
  PAYMENT_HISTORY,
  CANCEL_SUBSCRIPTION,
  MANAGE_SUBSCRIPTION,
  PAYMENT_CANCEL,
  PAYMENT,
}

const initialState = {
  data: null,
  step: null,
  auth: false,
  signup: false,
  buyoffer: false,
  forgotpassword: false,
  resetpassword: false,
  paymenthistory: false,
  paymentcancel: false,
  payment: false,
  skipOffers: false,
};

export enum dialogActionTypes {
  DIALOG_AUTH = 'DIALOG_AUTH',
  DIALOG_SIGNUP = 'DIALOG_SIGNUP',
  DIALOG_BUY_OFFER = 'DIALOG_BUY_OFFER',
  DIALOG_FORGOTPASSWORD = 'DIALOG_FORGOTPASSWORD',
  DIALOG_PAYMENT_HISTORY = 'DIALOG_PAYMENT_HISTORY',
  DIALOG_PAYMENT_CANCEL = 'DIALOG_PAYMENT_CANCEL',
  DIALOG_PAYMENT = 'DIALOG_PAYMENT',
  DIALOG_CANCEL_SUBSCRIPTION = 'DIALOG_CANCEL_SUBSCRIPTION',
  DIALOG_MANAGE_SUBSCRIPTION = 'DIALOG_MANAGE_SUBSCRIPTION',
  DIALOG_RESET_PASSWORD = 'DIALOG_RESET_PASSWORD',
  CLOSE_ALL = 'CLOSE_ALL',
  PREVIOUS_STEP = 'PREVIOUS_STEP',
}

type SelectedId = string | null;

type Action =
  | {
      type: dialogActionTypes.DIALOG_AUTH;
      payload?: {
        email?: string;
        error?: string;
        selectedOffer?: IOfferItem;
        offerIds?: IOfferIds[];
        canBuyHavingOffer?: boolean;
        skipOffers?: boolean;
      };
    }
  | {
      type: dialogActionTypes.DIALOG_SIGNUP;
      payload: {
        selectedId: SelectedId;
        typename: OfferType;
        offerIds?: IOfferIds[];
      };
    }
  | {
      type: dialogActionTypes.DIALOG_PAYMENT_HISTORY;
      payload: IPayment;
    }
  | {
      type: dialogActionTypes.DIALOG_PAYMENT_CANCEL;
      payload: { offerId?: string };
    }
  | {
      type: dialogActionTypes.DIALOG_BUY_OFFER;
      payload: {
        selectedId?: SelectedId;
        offerIds?: IOfferIds[];
        typename: OfferType;
      };
    }
  | {
      type: dialogActionTypes.DIALOG_PAYMENT;
      payload: {
        type: PaymentType;
        result?: AdyenPaymentResultCode;
        selectedOffer?: IOfferItem | null;
        originalOfferId?: string;
        promoCode?: string;
        reducedPrice?: number;
      };
    }
  | { type: dialogActionTypes.DIALOG_FORGOTPASSWORD; payload?: { email?: string } }
  | { type: dialogActionTypes.DIALOG_CANCEL_SUBSCRIPTION; payload?: { offerId: string } }
  | { type: dialogActionTypes.DIALOG_MANAGE_SUBSCRIPTION; payload?: { offerId: string } }
  | { type: dialogActionTypes.DIALOG_RESET_PASSWORD }
  | { type: dialogActionTypes.CLOSE_ALL };

export interface State {
  step: AuthorizationSteps | null;
  data?:
    | ({
        email?: string;
        error?: string;
        typename?: OfferType;
        entitlement?: EntitlementsNode;
        offerIds?: IOfferIds[];
        canBuyHavingOffer?: boolean;
        skipOffers?: boolean;
        selectedId?: SelectedId;
        type?: PaymentType;
        result?: AdyenPaymentResultCode;
        offerId?: string;
        selectedOffer?: IOfferItem | null;
        originalOfferId?: string;
        reducedPrice?: number;
        promoCode?: string;
      } & Partial<IPayment>)
    | null;
  auth: boolean;
  signup: boolean;
  buyoffer: boolean;
  forgotpassword: boolean;
  resetpassword: boolean;
  paymenthistory: boolean;
  paymentcancel: boolean;
  payment: boolean;
}
type Dispatch = (action: Action) => void;

const DialogStateContext = createContext<State | undefined>(undefined);
const DialogDispatchContext = createContext<Dispatch | undefined>(undefined);

function dialogReducer(state: State, action: Action): State {
  switch (action.type) {
    case dialogActionTypes.DIALOG_AUTH:
      return {
        ...state,
        data: {
          ...action.payload,
        },
        auth: !state.auth,
        step: AuthorizationSteps.AUTH,
      };

    case dialogActionTypes.DIALOG_BUY_OFFER:
      return {
        ...state,
        data: action.payload,
        buyoffer: !state.buyoffer,
        step: AuthorizationSteps.BUY_OFFER,
      };

    case dialogActionTypes.DIALOG_PAYMENT:
      return {
        ...state,
        data: action.payload,
        payment: true,
        step: AuthorizationSteps.PAYMENT,
      };

    case dialogActionTypes.DIALOG_FORGOTPASSWORD:
      return {
        ...state,
        data: action.payload,
        forgotpassword: !state.forgotpassword,
        step: AuthorizationSteps.FORGOT_PASSWORD,
      };

    case dialogActionTypes.DIALOG_RESET_PASSWORD:
      return {
        ...state,
        resetpassword: true,
        step: AuthorizationSteps.RESET_PASSWORD,
      };

    case dialogActionTypes.DIALOG_PAYMENT_HISTORY:
      return {
        ...state,
        data: action.payload,
        paymenthistory: true,
        step: AuthorizationSteps.PAYMENT_HISTORY,
      };

    case dialogActionTypes.DIALOG_PAYMENT_CANCEL:
      return {
        ...state,
        data: action.payload,
        paymentcancel: true,
        step: AuthorizationSteps.PAYMENT_CANCEL,
      };

    case dialogActionTypes.DIALOG_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        data: { ...action.payload },
        step: AuthorizationSteps.CANCEL_SUBSCRIPTION,
      };

    case dialogActionTypes.DIALOG_MANAGE_SUBSCRIPTION:
      return {
        ...state,
        data: { ...action.payload },
        step: AuthorizationSteps.MANAGE_SUBSCRIPTION,
      };

    case dialogActionTypes.CLOSE_ALL:
      return initialState;

    default:
      return state;
  }
}

type DialogProviderProps = PropsWithChildren;

const DialogProvider: FC<DialogProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(dialogReducer, initialState);
  const router = useRouter();

  useMountEffect(() => {
    if (router.query.code ?? router.query.email) {
      dispatch({ type: dialogActionTypes.DIALOG_AUTH });
    }
  });

  return (
    <DialogStateContext.Provider value={state}>
      <DialogDispatchContext.Provider value={dispatch}>
        <SubscriptionProvider>
          <AuthorizationDialog step={state.step} data={state.data} />
          {children}
        </SubscriptionProvider>
      </DialogDispatchContext.Provider>
    </DialogStateContext.Provider>
  );
};

function useDialogState() {
  const context = useContext(DialogStateContext);
  if (context === undefined) {
    throw new Error('useDialogState must be used within a DialogProvider');
  }
  return context;
}

function useDialogDispatch() {
  const context = useContext(DialogDispatchContext);
  if (context === undefined) {
    throw new Error('useDialogDispatch must be used within a DialogProvider');
  }
  return context;
}

export { DialogProvider, useDialogState, useDialogDispatch };
