import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import { media } from '@/styles';

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
`;

export const Error = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
