import { Box, FormControl } from '@material-ui/core';
import { Dispatch, FC, FormEvent, SetStateAction, useCallback, useState } from 'react';
import { InfoWrapper, InfoWrapperButtons } from '../AuthorizationDialog.styles';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';

import { Button } from '@/components/Button/Button';
import { IOfferIds } from '@/models/ILandingPage.interface';
import { IOfferItem } from '@/models/IOffer.interface';
import { Offer } from '@/models/IEntitlement.interface';
import { OfferType } from '@/models/OfferTypeName.enum';
import Payment from '@/components/Payment';
import PaymentErrorMessage from '@/components/PaymentErrorMessage';
import { PaymentType } from '@/models/Payment.enum';
import { PromoCodeField } from '@/components/PromoCodeField';
import { SelectOffer } from '@/components/SelectOffer';
import { SelectedOffer } from './SelectedOffer';
import { Typography } from '@/components/Typography';
import axios from 'axios';
import { css } from '@emotion/react';
import { useAccountState } from '@/context/accountContext';
import { useAuthorizationDialog } from '../AuthorizationDialog.hooks';

enum BuyOfferStep {
  SelectOffer,
  Payment,
}

interface BuyOfferDialogProps {
  originalOfferId?: string;
  typename?: OfferType;
  offerIds: IOfferIds[];
  selectedOffer: IOfferItem | null;
  onSelectOffer: (offer: IOfferItem) => void;
}

export const BuyOfferDialogStep: FC<BuyOfferDialogProps> = ({
  offerIds,
  originalOfferId,
  selectedOffer,
  onSelectOffer,
}) => {
  const { auth } = useAccountState();
  const { validatePromoCode } = useAuthorizationDialog({ auth });
  const dialogDispatch = useDialogDispatch();

  const [promoCode, setPromoCode] = useState<string>();
  const [invalidPromoCode, setInvalidPromoCode] = useState(false);
  const [reducedPrice, setReducedPrice] = useState<number>();

  const [promoCodeOffer, setPromoCodeOffer] = useState<IOfferItem | null>(null);

  const handlePromoCodeChange = (code: string) => {
    setInvalidPromoCode(false);
    setPromoCode(code);
  };

  const handleValidatePromoCode = async () => {
    if (promoCode && selectedOffer?.id) {
      const promoCodeStatus = await validatePromoCode(
        selectedOffer.id === 'pass-offer' ? offerIds?.[0].offerId ?? '' : selectedOffer.id,
        promoCode
      );
      if (promoCodeStatus?.status === 'wrong-offer' && promoCodeStatus.offer) {
        setPromoCodeOffer(promoCodeStatus.offer);
      }
      if (promoCodeStatus?.status !== 'valid') {
        setInvalidPromoCode(true);
        return false;
      }
      try {
        const { data } = await axios.post('/api/promo-code', {
          token: auth.token,
          offerId: selectedOffer.id,
          code: promoCode,
        });
        if (data) {
          setReducedPrice(data.reducedPrice);
        } else {
          setInvalidPromoCode(true);
          return false;
        }
      } catch {
        setReducedPrice(undefined);
      }
      return promoCodeStatus?.status === 'valid';
    }
    return false;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (promoCode && selectedOffer?.id) {
      const validPromoCode = await handleValidatePromoCode();
      if (!validPromoCode) {
        setInvalidPromoCode(true);
        return;
      }
    }

    dialogDispatch({
      type: dialogActionTypes.DIALOG_PAYMENT,
      payload: {
        type: PaymentType.MakePayment,
        selectedOffer: selectedOffer,
        originalOfferId: originalOfferId ?? undefined,
        promoCode: promoCode?.toString() ?? undefined,
        reducedPrice: reducedPrice,
      },
    });
  };

  return (
    <>
      {promoCodeOffer && (
        <InfoWrapper>
          <Typography variant="titleMedium">
            Din kampanjkod gäller för {promoCodeOffer.title}, gå vidare för att aktivera {promoCodeOffer.title}.
          </Typography>
          <InfoWrapperButtons>
            <Button color="red" size="large" onClick={() => setPromoCodeOffer(null)}>
              Avbryt
            </Button>
            <Button
              size="large"
              color="red"
              onClick={() => {
                onSelectOffer(promoCodeOffer);
                setPromoCodeOffer(null);
                setInvalidPromoCode(false);
              }}
            >
              Byt abonnemangstyp
            </Button>
          </InfoWrapperButtons>
        </InfoWrapper>
      )}

      {auth.error && (
        <Typography
          variant="bodySmall"
          styles={css`
            text-align: center;
          `}
        >
          Betalning kan inte påbörjas – eventuell kampanjkod är ogiltig eller förbrukad, skriv in korrekt kampanjkod
          eller mejla kontakt@drakenfilm.se
        </Typography>
      )}

      <>
        <Box mb={10}>
          <form autoComplete="off" onSubmit={(evt) => handleSubmit(evt)}>
            {selectedOffer?.id}
            <SelectOffer includedIds={offerIds} selectedOffer={selectedOffer} onSelectedChange={onSelectOffer} />
            <Box mb={2} mt={5}>
              <PromoCodeField onSetValue={handlePromoCodeChange} promoCode={promoCode} error={invalidPromoCode} />
            </Box>
            <FormControl fullWidth margin="normal">
              <Button size="small" color="red" disabled={!selectedOffer?.id || invalidPromoCode}>
                Gå vidare
              </Button>
            </FormControl>
          </form>
        </Box>
      </>
    </>
  );
};
