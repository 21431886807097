import { Drawer, DialogProps as MuiDialogProps } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Close as CloseIcon } from '@material-ui/icons';
import { FC, PropsWithChildren } from 'react';

import { media } from '@/styles';

import { CloseButton, CloseWrapper, Content, Header } from './Dialog.styles';

interface DialogProps extends PropsWithChildren {
  open?: boolean;
  scroll?: MuiDialogProps['scroll'];
  onClose: MuiDialogProps['onClose'];
}

export const Dialog: FC<DialogProps> = ({ open = true, onClose, children }) => {
  const mdMatch = useMediaQuery(media.md);

  return (
    <Drawer open={open} anchor="right" onClose={onClose} hideBackdrop={!mdMatch}>
      <Header>
        {/* <div>Tillbaka</div> */}
        <CloseWrapper>
          <CloseButton
            disableElevation
            disableRipple
            disableFocusRipple
            variant="text"
            onClick={(event) => onClose?.(event, 'escapeKeyDown')}
          >
            Stäng <CloseIcon />
          </CloseButton>
        </CloseWrapper>
      </Header>
      <Content>{children}</Content>
    </Drawer>
  );
};
