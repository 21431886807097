import { Typography } from '@material-ui/core';
import { FC } from 'react';

import { InfoWithButton } from './InfoWithButton';

interface ReactivationStepProps {
  onButtonClick?: () => void;
}
export const ReactivationStep: FC<ReactivationStepProps> = ({ onButtonClick }) => {
  return (
    <InfoWithButton text="Välkommen tillbaka till Draken Film" buttonText="Stäng" onButtonClick={onButtonClick}>
      <Typography>
        Din prenumeration fortsätter tills vidare, du har ingen bindningstid och kan säga upp den när du vill.
      </Typography>
    </InfoWithButton>
  );
};
