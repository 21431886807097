import '@adyen/adyen-web/dist/adyen.css';

import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  IPaymentRequest,
  IPaymentRequestBase,
  IPaymentResponse,
  ISavePaymentRequest,
} from '@/models/IPayment.interface';
import { IPaymentResultRequest, IPaymentResultResponse } from '@/models/IPaymentResult.interface';
import axios, { AxiosResponse } from 'axios';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';

import { AdyenPaymentResultCode } from '@/models/AdyenPaymentResultCode.enum';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import { IAdyenPaymentResult } from '@/models/IAdyenPaymentResult.interface';
import { ICampaignResponse } from '@/models/ICampaign.interface';
import { IPaymentMethodsResponse } from '@/models/IPaymentMethods.interface';
import { PaymentMethodsResponseObject } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types';
import { PaymentType } from '@/models/Payment.enum';
import { Typography } from '@material-ui/core';
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { getPaymentResultMessage } from '@/utils/paymentResultUtils';
import { orderComplete } from '@/utils/analytics';
import { useAccountState } from '@/context/accountContext';
import { useRouter } from 'next/router';

interface PaymentProps {
  type?: PaymentType;
  offerId?: string;
  originalOfferId?: string;
  promoCode?: string;
  result?: AdyenPaymentResultCode;
  onError?: () => void;
}

const Payment: FC<PaymentProps> = ({
  type = PaymentType.MakePayment,
  originalOfferId,
  offerId,
  promoCode,
  result,
  onError,
}) => {
  const [message, setMessage] = useState<string | undefined>();

  const dropInWrapperRef = useRef<HTMLDivElement | null>(null);
  const dropInElementRef = useRef<DropinElement | null>(null);
  const priceRef = useRef<number | null>(null);
  const currencyRef = useRef<string | null>(null);
  const transactionRef = useRef<string | null>(null);
  const resultRef = useRef<AdyenPaymentResultCode | null>(result ?? null);
  const router = useRouter();

  const { auth } = useAccountState();

  const dialogDispatch = useDialogDispatch();

  const getPaymentRequestBase = useCallback(
    (stateData: unknown): IPaymentRequestBase => {
      const returnUrl = new URL(window.location.href.replace(window.location.search, ''));
      if (type === PaymentType.SavePayment) {
        returnUrl.searchParams.append('savePayment', 'true');
      }
      if (offerId) {
        returnUrl.searchParams.append('offerId', offerId);
      }
      if (promoCode) {
        returnUrl.searchParams.append('promoCode', promoCode);
      }

      const resultUrl = new URL(`${window.location.origin}/payment-result`);
      resultUrl.searchParams.append('returnUrl', returnUrl.href);

      return {
        returnUrl: resultUrl.href,
        dropInEventData: JSON.stringify(stateData),
      };
    },
    [offerId, promoCode, type]
  );

  const makePayment = useCallback(
    async (stateData: unknown): Promise<AxiosResponse<IPaymentResponse>> => {
      const data: IPaymentRequest = {
        ...getPaymentRequestBase(stateData),
        offerId,
        ...(!!promoCode ? { 'code': promoCode } : {}),
        ...(!!originalOfferId ? { 'originalOfferId': originalOfferId } : {}),
        currency: currencyRef.current,
        netAmountCents: priceRef.current,
      };
      if (!!originalOfferId) {
        return await axios.post<IPaymentResponse>(`/api/adyen/payment/upgrade/${auth.token}`, data);
      }
      return await axios.post<IPaymentResponse>(`/api/adyen/payment/${auth.token}`, data);
    },
    [auth.token, getPaymentRequestBase, offerId, promoCode, originalOfferId]
  );

  const savePayment = useCallback(
    async (stateData: unknown): Promise<AxiosResponse<IPaymentResponse>> => {
      const data: ISavePaymentRequest = getPaymentRequestBase(stateData);
      return await axios.post<IPaymentResponse>(`/api/adyen/savepayment/${auth.token}`, data);
    },
    [auth.token, getPaymentRequestBase]
  );

  const handleResult = useCallback(
    (resultCode: AdyenPaymentResultCode | null, dropIn: UIElement): void => {
      const isSuccess = resultCode === AdyenPaymentResultCode.Authorised;
      const status = isSuccess ? 'success' : 'error';
      // const props = resultCode && { message: getPaymentResultMessage(resultCode, type) };
      dropIn.setStatus(status);

      if (isSuccess) {
        orderComplete(auth?.userId);
      }
    },
    [auth?.userId]
  );

  const handleSuccess = useCallback(() => {
    router.push('/filmer').then(() => {
      router.reload();
    });

    dialogDispatch({
      type: dialogActionTypes.CLOSE_ALL,
    });
  }, [dialogDispatch, router]);

  const handleAdyenResponse = useCallback(
    (adyenResponseString: string, dropIn: UIElement) => {
      const adyenResponse = JSON.parse(adyenResponseString);

      if (adyenResponse.action) {
        dropIn.handleAction(adyenResponse.action);
        return;
      }

      const paymentResult = adyenResponse as IAdyenPaymentResult;

      if (type === PaymentType.MakePayment && paymentResult.resultCode === AdyenPaymentResultCode.Authorised) {
        handleSuccess();
        return;
      }

      handleResult(paymentResult.resultCode, dropIn);
    },
    [handleResult, type, handleSuccess]
  );

  const initPayment = useCallback(async () => {
    const { data } = await axios.post<IPaymentMethodsResponse>(`/api/adyen/paymentmethod/${auth.token}`, {
      offerId,
      ...(promoCode ? { promoCode } : {}),
      ...(originalOfferId ? { originalOfferId: originalOfferId } : {}),
    });

    if (data.errors) {
      if (onError) {
        onError();
      }
      return;
    }

    if (!data.data) {
      return;
    }

    const { paymentMethodsResponse, clientKey, priceInCents, currency } = data.data.viewer.adyenDropInPaymentMethods;

    priceRef.current = priceInCents;
    currencyRef.current = currency;

    if (promoCode) {
      const { data: campaignResponse } = await axios.post<ICampaignResponse>('/api/campaign', {
        promoCode,
      });

      if (campaignResponse.data?.viewer.campaign?.bypassPaymentMethodRegistration) {
        const { data } = await makePayment(null);
        if (data.errors) {
          if (onError) {
            onError();
          }
        } else {
          handleSuccess();
        }
        return;
      }
    }

    // Page breaks when importing at the top of the file
    const AdyenCheckout = (await import('@adyen/adyen-web')).default;

    const locale = 'sv-SE';

    const environment = clientKey.startsWith('test_') ? 'test' : 'live';

    const translations =
      type === PaymentType.SavePayment
        ? {
            [locale]: {
              payButton: 'Spara',
            },
          }
        : undefined;

    const options: CoreOptions = {
      paymentMethodsResponse: JSON.parse(paymentMethodsResponse) as PaymentMethodsResponseObject,
      clientKey,
      environment,
      locale,
      amount: {
        value: priceInCents,
        currency,
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          styles: {
            base: {
              color: 'red',
              backgroundColor: 'red',
              fontSmoothing: 'antialiased',
            },
          },
        },
      },
      translations,
    };

    const checkout = await new AdyenCheckout(options);
    const dropInElement = checkout.create('dropin', {
      showStoredPaymentMethods: type === PaymentType.MakePayment,
      onReady: () => {
        if (resultRef.current) {
          handleResult(resultRef.current, dropInElement);
          resultRef.current = null;
        }
      },
      onSubmit: async (state, dropIn) => {
        if (!state.isValid) return;

        const { data } =
          type === PaymentType.MakePayment ? await makePayment(state.data) : await savePayment(state.data);

        if (!data.data || data.errors) {
          handleResult(null, dropIn);
          return;
        }

        const { adyenResponse, transactionId } = data.data.result;
        transactionRef.current = transactionId;

        handleAdyenResponse(adyenResponse, dropIn);
      },
      onAdditionalDetails: async (state, dropIn) => {
        // handle response from payment systems

        const requestData: IPaymentResultRequest = {
          transactionId: transactionRef.current ?? '',
          details: JSON.stringify(state.data.details),
        };

        const { data } = await axios.post<IPaymentResultResponse>(
          `/api/adyen/paymentdetails/${auth.token}`,
          requestData
        );

        if (!data.data || data.errors) {
          handleResult(null, dropIn);
          return;
        }

        handleAdyenResponse(data.data.result.adyenResponse, dropIn);
      },
    });

    dropInElementRef.current = dropInElement;

    return dropInElement.mount(dropInWrapperRef.current ?? '');
  }, [
    auth.token,
    handleAdyenResponse,
    handleResult,
    makePayment,
    offerId,
    onError,
    promoCode,
    savePayment,
    type,
    handleSuccess,
    originalOfferId,
  ]);

  useEffect(() => {
    if (!auth.token) {
      // Not logged in, cannot init dropin
      const message = result ? getPaymentResultMessage(result, type) : 'Användaren är inte inloggad';
      setMessage(message);
      return;
    }

    let dropIn: DropinElement | undefined;
    const init = async () => {
      setMessage(undefined);
      dropIn = await initPayment();
    };

    init();

    return function cleanup() {
      dropIn?.unmount();
    };
  }, [auth.token, initPayment, result, type]);

  if (message) {
    return <Typography variant="body2">{message}</Typography>;
  }

  return (
    <>
      <div ref={dropInWrapperRef} />
    </>
  );
};

export default Payment;
