import { Typography } from '@material-ui/core';
import { FC } from 'react';

import { InfoWithButton } from './InfoWithButton';

import { DiscountWrapper } from '../CancelSubscriptionDialog.styles';

interface ReactivationStepProps {
  onButtonClick?: () => void;
  onClose?: () => void;
  price?: number;
  recurringPeriod: string;
}
export const DiscountStep: FC<ReactivationStepProps> = ({ onButtonClick, onClose, price, recurringPeriod }) => {
  return (
    <InfoWithButton
      text="Vill du fortsätta prenumerera på Draken Film till halva priset?"
      buttonText="Stäng"
      buttonColor="default"
      onButtonClick={onClose}
    >
      <div>
        <DiscountWrapper onClick={onButtonClick}>
          Prenumerera för {price ? price / 2 / 100 : '-'} SEK {recurringPeriod}{' '}
        </DiscountWrapper>
        <Typography>Rabatten gäller under 12 månader.</Typography>
      </div>
    </InfoWithButton>
  );
};
