import { Header, Input, Label, Price, PriceWrapper, UspList, UspListItem, Wrapper } from './Offer.styles';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';

import { Box } from '@material-ui/core';
import { Button } from '@/components/Button/Button';
import { Check } from '@material-ui/icons';
import { FC } from 'react';
import { IOfferItem } from '@/models/IOffer.interface';
import { OffersStylesConfig } from '@/payload-types';
import { PaymentType } from '@/models/Payment.enum';
import { Typography } from '@/components/Typography';
import { getOfferColor } from '@/utils/offers';
import { media } from '@/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface OfferProps {
  data: IOfferItem;
  checked: boolean;
  reducedPrice?: number;
  offersStylesConfig?: OffersStylesConfig;
  handleChange?: () => void;
}

export const Offer: FC<OfferProps> = ({ data, checked, reducedPrice, handleChange, offersStylesConfig }) => {
  const mdMatch = useMediaQuery(media.md);

  return (
    <>
      <Input type="radio" name="offers" id={data.id} checked={handleChange && checked} onChange={handleChange} />
      <Wrapper
        color={
          offersStylesConfig?.backgroundColor
            ? offersStylesConfig?.backgroundColor
            : getOfferColor(data?.recurringPeriod?.unit)
        }
      >
        <Label htmlFor={data.id}>
          <Header expanded={checked}>
            <Typography variant="bodyLarge">{data.title}</Typography>
            {data.priceInCents > 0 && (
              <div>
                <PriceWrapper>
                  <Price isDiscounted={!!reducedPrice}>
                    <Typography variant="bodyLarge" fontStyle="italic">
                      {data.priceInCents / 100} {data.currency}
                    </Typography>
                  </Price>
                  {reducedPrice && (
                    <Price>
                      <Typography variant="bodyLarge" fontStyle="italic">
                        {reducedPrice / 100} {data.currency}
                      </Typography>
                    </Price>
                  )}
                </PriceWrapper>
              </div>
            )}
            {checked && mdMatch && (
              <UspList>
                {data.usps.map((item: string, idx: number) => (
                  <UspListItem key={idx}>
                    <Check color="primary" />
                    <Box ml={2}>
                      <Typography variant="bodySmall"> {item}</Typography>
                    </Box>
                  </UspListItem>
                ))}
              </UspList>
            )}
          </Header>
        </Label>
      </Wrapper>
    </>
  );
};
