import { FC, PropsWithChildren } from 'react';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography';
import { InfoWrapper } from '@/components/_overlays/CancelSubscriptionDialog/CancelSubscriptionDialog.styles';

interface InfoWithButtonProps extends PropsWithChildren {
  text: string;
  buttonText: string;
  onButtonClick?: () => void;
  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'default';
}

export const InfoWithButton: FC<InfoWithButtonProps> = ({ children, text, buttonText, onButtonClick }) => {
  return (
    <InfoWrapper>
      <Typography variant="titleMedium">{text}</Typography>
      {children}
      <Button onClick={onButtonClick} color="red">
        {buttonText}
      </Button>
    </InfoWrapper>
  );
};
