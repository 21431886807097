import { DiscountStep, FinishStep, InfoWithButton, ReactivationStep, SurveyStep } from './CancelSubscriptionSteps';
import { FC, useRef, useState } from 'react';
import { LIKE_LEVEL, REASONS } from './CancelSubscriptionSteps/CancelSubscriptionSteps.consts';

import axios from 'axios';
import { cancelSubscriptionClick } from '@/utils/analytics';
import formatDate from '@/utils/formatDate';
import getMeta from '@/utils/getMeta';
import { getPromoCodeByOfferId } from '@/utils/getPromoCodeByOfferId';
import { getRecurringPeriodUnit } from '@/utils/time';
import { useAccountState } from '@/context/accountContext';
import { useForm } from 'react-hook-form';
import { useSubscriptionData } from '@/context/subscription/subscription.provider';

export const enum CancelSubscriptionSteps {
  CONFIRMATION,
  SURVEY,
  DISCOUNT,
  REACTIVATION,
  FINISH,
}

export interface CancelSubscriptionSurveyValues {
  likeLevel: '0' | '1' | '2' | '3' | '4';
  otherReson: string | undefined;
  reason: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';
}

interface CancelSubscriptionDialogProps {
  offerId: string | undefined;
}

export const CancelSubscriptionDialog: FC<CancelSubscriptionDialogProps> = ({ offerId }) => {
  const [step, setStep] = useState<CancelSubscriptionSteps>(CancelSubscriptionSteps.CONFIRMATION);
  const modalContentRef = useRef<HTMLDivElement>(null);

  const { control, watch } = useForm<CancelSubscriptionSurveyValues>();
  const { auth } = useAccountState();
  const { subscriptionData } = useSubscriptionData();

  const choosenEntitlement = subscriptionData?.data.viewer.entitlements?.edges?.find(
    (edge) => edge.node.offerId === offerId
  );

  const choosenSubscription = subscriptionData?.data.viewer.subscriptions?.edges?.find(
    (edge) => edge.node.offerId === offerId
  );

  const hasValidPromotion =
    choosenSubscription?.node.promoCode?.remaining && choosenSubscription?.node.promoCode?.remaining > 0;

  const handleUnsubscribe = async () => {
    await axios.post(`/api/auth/subscription/cancel/${offerId}`, {
      token: auth.token,
    });

    cancelSubscriptionClick(getMeta('pageType'));

    setStep(CancelSubscriptionSteps.SURVEY);
  };

  const handleClose = () => {
    if (step === CancelSubscriptionSteps.FINISH || step === CancelSubscriptionSteps.SURVEY) {
      setStep(0);
    }
    // onClose();
  };

  const onReactivationClose = () => {
    if (step === CancelSubscriptionSteps.FINISH) {
      setStep(0);
    }
    // onClose();
  };

  const handleSubmitSurveyData = async () => {
    const data = watch();
    const likeLevel = LIKE_LEVEL.find((level) => level.value === data.likeLevel)?.tagName ?? '';
    const reason = REASONS.find((reason) => reason.value === data.reason)?.tagName ?? '';
    const otherReason = data?.otherReson ?? '';

    const tags = [];

    if (likeLevel) {
      tags.push({ name: likeLevel, status: 'active' });
    }

    if (reason) {
      tags.push({ name: reason, status: 'active' });
    }

    await axios.post(`/api/tags`, {
      tags,
      email: auth.openId,
    });

    if (otherReason) {
      await axios.post(`/api/notes`, {
        note: `Survey other reason of cancellation: ${otherReason}`,
        email: auth.openId,
      });
    }

    if (data.reason === '7' && !hasValidPromotion) {
      setStep(CancelSubscriptionSteps.DISCOUNT);
    } else {
      setStep(CancelSubscriptionSteps.FINISH);
    }
  };

  const handleReactivate = async () => {
    try {
      await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/api/offers/uncancel/${offerId}/${auth.token}`, {
        userId: auth.userId,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error occured while uncancelling subscription', error);
    } finally {
      setStep(CancelSubscriptionSteps.REACTIVATION);
    }
  };

  const handleReactivateWithDiscount = async () => {
    try {
      await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/api/offers/uncancel/${offerId}/${auth.token}`, {
        userId: auth.userId,
      });
      await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/api/promo-code/apply/${auth.userId}`, {
        token: auth.token,
        promocode: getPromoCodeByOfferId(offerId ?? ''),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error occured while uncancelling subscription', error);
    } finally {
      setStep(CancelSubscriptionSteps.REACTIVATION);
    }
  };

  const stepComponents = {
    [CancelSubscriptionSteps.CONFIRMATION]: (
      <InfoWithButton
        text="Är du säker på att du vill säga upp din prenumeration?"
        buttonText="Säg upp prenumeration"
        onButtonClick={handleUnsubscribe}
      />
    ),
    [CancelSubscriptionSteps.SURVEY]: (
      <SurveyStep
        control={control}
        onReactivate={handleReactivate}
        onButtonClick={handleSubmitSurveyData}
        disableOtherReason={watch('reason') !== '8'}
        finishDate={choosenEntitlement?.node.entitledUntil ? formatDate(choosenEntitlement?.node?.entitledUntil) : ''}
      />
    ),
    [CancelSubscriptionSteps.DISCOUNT]: (
      <DiscountStep
        recurringPeriod={getRecurringPeriodUnit(choosenEntitlement?.node.offer.recurringPeriod?.unit)}
        price={choosenEntitlement?.node.offer.priceInCents}
        onButtonClick={handleReactivateWithDiscount}
        // onClose={onClose}
      />
    ),
    [CancelSubscriptionSteps.REACTIVATION]: <ReactivationStep onButtonClick={onReactivationClose} />,
    [CancelSubscriptionSteps.FINISH]: (
      <FinishStep
        // onButtonClick={onClose}
        finishDate={choosenEntitlement?.node.entitledUntil ? formatDate(choosenEntitlement?.node.entitledUntil) : ''}
      />
    ),
  };

  return <div className="transition-element">{stepComponents[step]}</div>;
};
