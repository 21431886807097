import { Box, Typography } from '@material-ui/core';
import { FC, useCallback, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';

import { AdyenPaymentResultCode } from '@/models/AdyenPaymentResultCode.enum';
import Payment from '@/components/Payment';
import PaymentErrorMessage from '@/components/PaymentErrorMessage';
import { PaymentType } from '@/models/Payment.enum';
import { SelectedOffer } from './SelectedOffer';
import { useDialogState } from '@/context/authorizationDialogContext';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'center',
    fontFamily: 'Radikal-medium',
    fontSize: 26,
    margin: theme.spacing(3, 0, 4),
  },
}));

interface PaymentDialogProps {
  type?: PaymentType;
  result?: AdyenPaymentResultCode;
  offerId?: string;
  originalOfferId?: string;
  promoCode?: string;
}

export const PaymentDialogStep: FC<PaymentDialogProps> = ({
  type,
  result,
  offerId: _offerId,
  originalOfferId: _originalOfferId,
  promoCode: _promoCode,
}) => {
  const classes = useStyles();
  const { data } = useDialogState();
  const [error, setError] = useState(false);

  const handleError = useCallback(() => {
    setError(true);
  }, []);

  let title: string | undefined;
  if (type === PaymentType.SavePayment) {
    title = 'Uppdatera betaluppgifter';
  } else if (result === AdyenPaymentResultCode.Authorised) {
    title = 'Välkommen till ditt nya filmliv!';
  }

  // Don't include offerId and promoCode when payment already successful
  const offerId = result !== AdyenPaymentResultCode.Authorised ? _offerId : undefined;
  const originalOfferId = result !== AdyenPaymentResultCode.Authorised ? _originalOfferId : undefined;
  const promoCode = result !== AdyenPaymentResultCode.Authorised ? _promoCode : undefined;

  return (
    <>
      offerId: {offerId}
      <br />
      originalOfferId: {originalOfferId}
      {title && (
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      )}
      <Box mb={4}>
        <SelectedOffer offer={data?.selectedOffer} reducedPrice={data?.reducedPrice} />
      </Box>
      <PaymentErrorMessage error={error} />
      <Payment
        type={type}
        result={result}
        offerId={offerId}
        originalOfferId={originalOfferId}
        promoCode={promoCode}
        onError={handleError}
      />
    </>
  );
};
