import { AuthDialogProps, AuthForm, LoginSteps } from '../AuthorizationDialog.types';
import { Box, FormControl } from '@material-ui/core';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Error, InfoText, InfoWrapper, InfoWrapperButtons } from '../AuthorizationDialog.styles';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { accountActionTypes, useAccountDispatch, useAccountState } from '@/context/accountContext';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';

import { Button } from '@/components/Button/Button';
import EmailStep from './steps/EmailStep';
import { IOfferItem } from '@/models/IOffer.interface';
import LoginStep from './steps/LoginStep';
import Payment from '@/components/Payment';
import { PromoCodeField } from '@/components/PromoCodeField';
import RegistrationStep from './steps/RegistrationStep';
import { SelectOffer } from '@/components/SelectOffer';
import SelectOfferStep from './steps/SelectOfferStep';
import { SelectedOffer } from '@/components/_overlays/AuthorizationDialog/AuthorizationSteps/SelectedOffer';
import axios from 'axios';
import { subscriptionTermsApproval } from '@/utils/analytics';
import { useAuthorizationDialog } from '@/components/_overlays/AuthorizationDialog/AuthorizationDialog.hooks';
import { useForm } from 'react-hook-form';
import { useMountEffect } from '@/hooks/useMountEffect';
import { useRouter } from 'next/router';

export const AuthDialogStep: FC<AuthDialogProps> = ({
  email = '',
  error,
  offerIds,
  selectedOffer,
  onSelectOffer,
  canBuyHavingOffer,
  skipOffers,
}) => {
  const router = useRouter();
  const emailQuery = router.query.email as string;
  const [promoCode, setPromoCode] = useState<string>((router.query.code as string) || '');
  const [invalidPromoCode, setInvalidPromoCode] = useState<boolean>();
  const [_error, _setError] = useState<string>();
  const [reducedPrice, setReducedPrice] = useState<number>();

  const [promoCodeOffer, setPromoCodeOffer] = useState<IOfferItem | null>(null);
  const { dispatch: accountDispatch } = useAccountDispatch();

  const nodeRef = useRef<HTMLDivElement>(null);

  const dialogDispatch = useDialogDispatch();
  const { auth } = useAccountState();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<AuthForm>({
    defaultValues: { email: emailQuery, password: '', repeatPassword: '', terms: false, marketing: false },
  });

  const { validatePromoCode, logInUser, registerUser, step, setStep, hasOffer } = useAuthorizationDialog({
    emailQuery,
    auth,
    canBuyHavingOffer,
    skipOffers,
  });

  const handleValidatePromoCode = async () => {
    if (promoCode && selectedOffer?.id) {
      const promoCodeStatus = await validatePromoCode(
        selectedOffer.id === 'pass-offer' ? offerIds[0].offerId : selectedOffer.id,
        promoCode
      );
      if (promoCodeStatus?.status === 'wrong-offer' && promoCodeStatus.offer) {
        setPromoCodeOffer(promoCodeStatus.offer);
      }
      if (promoCodeStatus?.status !== 'valid') {
        setInvalidPromoCode(true);
        return false;
      }
      try {
        const { data } = await axios.post('/api/promo-code', {
          token: auth.token,
          offerId: selectedOffer.id,
          code: promoCode,
        });
        if (data) {
          setReducedPrice(data.reducedPrice);
        } else {
          setInvalidPromoCode(true);
          return false;
        }
      } catch {
        setReducedPrice(undefined);
      }
      return promoCodeStatus?.status === 'valid';
    }
    return false;
  };

  const formEmail = getValues('email');

  // Remove query params when auth dialog is open
  useMountEffect(() => {
    if (!window.location.search) {
      return;
    }
    const params = new URLSearchParams(window.location.search);
    params.delete('email');
    params.delete('code');
    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
  });

  // Reset promo code error on step change
  useEffect(() => {
    if (step) {
      setInvalidPromoCode(undefined);
      accountDispatch({ type: accountActionTypes.ERROR, payload: false });
    }
  }, [step, accountDispatch]);

  const goToPreviousStep = () => {
    setStep((prevState) => --prevState);
  };

  const handleProcessForm = async (data: AuthForm) => {
    if (step === LoginSteps.EMAIL) {
      try {
        const result = await axios.post('/api/auth/users/lookup', { email: data.email });
        setStep(result.data ? LoginSteps.LOGIN : LoginSteps.REGISTRATION);
      } catch {
        _setError('Unknown error. Please try again.');
      }
    }
    if (step === LoginSteps.USER_EXISTS) {
      if (auth.openId && promoCode) {
        const promoCodeValid = await handleValidatePromoCode();
        if (!promoCodeValid) {
          return;
        }
      }
      setStep(auth.openId ? LoginSteps.PAYMENT : LoginSteps.LOGIN);
    }
    if (step === LoginSteps.LOGIN) {
      await logInUser(data.email, data.password);
    }
    if (step === LoginSteps.SELECT_OFFER) {
      if (promoCode) {
        const promoCodeValid = await handleValidatePromoCode();
        if (!promoCodeValid) {
          return;
        }
      }
      setStep(LoginSteps.PAYMENT);
    }
    if (step === LoginSteps.REGISTRATION) {
      subscriptionTermsApproval(true);
      await registerUser(data.email, data.password, data.marketing);
    }
  };

  const handleForgotPassword = () => {
    dialogDispatch({ type: dialogActionTypes.DIALOG_FORGOTPASSWORD, payload: { email: formEmail } });
  };

  const formButtonText = useMemo(() => {
    switch (step) {
      case LoginSteps.REGISTRATION:
        return 'Skapa konto';
      case LoginSteps.LOGIN:
        return 'Logga in';
      default:
        return 'Gå vidare';
    }
  }, [step]);

  const offerSelectable = [LoginSteps.USER_EXISTS, LoginSteps.SELECT_OFFER].includes(step) && !skipOffers;

  const shouldShowSelectedOffer =
    ![LoginSteps.EMAIL, LoginSteps.LOGIN, LoginSteps.REGISTRATION].includes(step) && !skipOffers;

  return (
    <>
      {promoCodeOffer && (
        <InfoWrapper>
          <InfoText variant="h3">
            Din kampanjkod gäller för {promoCodeOffer.title}, gå vidare för att aktivera {promoCodeOffer.title}.
          </InfoText>
          <InfoWrapperButtons>
            <Button color="red" size="large" onClick={() => setPromoCodeOffer(null)}>
              Avbryt
            </Button>
            <Button
              size="large"
              color="red"
              onClick={() => {
                onSelectOffer(promoCodeOffer);
                setPromoCodeOffer(null);
                setInvalidPromoCode(false);
              }}
            >
              Byt abonnemangstyp
            </Button>
          </InfoWrapperButtons>
        </InfoWrapper>
      )}
      {(error ?? _error) && (
        <Box mb={2} mt={4} display="flex" flexDirection="column" alignItems="center">
          <Error variant="body2" color="error">
            {error ?? _error}
          </Error>
        </Box>
      )}
      {!promoCodeOffer && (
        <form autoComplete="off" onSubmit={handleSubmit(handleProcessForm)} noValidate>
          {offerSelectable ? (
            <SelectOffer includedIds={offerIds} selectedOffer={selectedOffer} onSelectedChange={onSelectOffer} />
          ) : (
            !hasOffer && shouldShowSelectedOffer && <SelectedOffer offer={selectedOffer} reducedPrice={reducedPrice} />
          )}
          <Box mb={3}>
            {offerSelectable && (
              <Box mb={10}>
                <PromoCodeField onSetValue={setPromoCode} promoCode={promoCode} error={!!invalidPromoCode} />
              </Box>
            )}
            <Box mt={10}>
              <SwitchTransition>
                <CSSTransition
                  key={step}
                  nodeRef={nodeRef}
                  addEndListener={(done) => {
                    nodeRef.current?.addEventListener('transitionend', done, false);
                  }}
                  classNames="slide-and-fade"
                >
                  <div ref={nodeRef}>
                    <div className="transition-element">
                      {step === LoginSteps.EMAIL && <EmailStep control={control} email={email} errors={errors} />}
                      {(step === LoginSteps.USER_EXISTS || step === LoginSteps.SELECT_OFFER) && (
                        <SelectOfferStep formEmail={formEmail} auth={auth} goToPreviousStep={goToPreviousStep} />
                      )}
                      {step === LoginSteps.LOGIN && (
                        <LoginStep
                          auth={auth}
                          formEmail={formEmail}
                          setStep={setStep}
                          control={control}
                          errors={errors}
                          handleForgotPassword={handleForgotPassword}
                        />
                      )}
                      {step === LoginSteps.REGISTRATION && (
                        <RegistrationStep control={control} getValues={getValues} errors={errors} />
                      )}
                      {step === LoginSteps.PAYMENT && <Payment offerId={selectedOffer?.id} promoCode={promoCode} />}
                    </div>
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </Box>
          </Box>
          <FormControl fullWidth margin="normal">
            {step !== LoginSteps.PAYMENT && (
              <Button size="large" color="red" disabled={isSubmitting} data-testid="login-form-submit-button">
                {formButtonText}
              </Button>
            )}
          </FormControl>
        </form>
      )}
    </>
  );
};
