export const getPromoCodeByOfferId = (offerId: string) => {
  switch (offerId) {
    case '88XJ5X9N1JUGDUDT6G1TYBRKIOFR':
      return 'V3MANAD50';
    case '79YSD8IPOA9KFMPG0AIUCQ3YTOFR':
      return 'V2MANAD50';
    case '5P3VFU1YYLHMJFHLQ8FYPI2SGOFR':
      return 'V2ARLIG50';
    case 'ADXNVEM6C7Z4M006PR47Y0BNKOFR':
      return 'V3ARLIG50';
    case '7JPGTDZ3BL5ISHQTW76B6MFQ6OFR':
      return 'V4ARLIG50';
    case '793FO884QYUBWES4M12FMDI8JOFR':
      return 'MANAD50';
    case '1GA7AC4N9XW5VKTI7UGGNZT6TOFR':
      return 'ARLIG50';
    case 'EBJQ7EAOJWJVPGBV3HQIYZZ6XOFR':
      return 'V5ARLIG50';
    default:
      return '';
  }
};
