import { ISpecialOffer } from '@/models/ISettings.interface';
import { EntitlementsNode, ISubscription, SubscriptionsEdge } from '@/models/ISubscription.interface';
import formatDate from '@/utils/formatDate';
import { getRecurringPeriodUnit } from '@/utils/time';

export enum SubscriptionEnum {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  TEST_PERIOD = 'TEST_PERIOD',
  DISCOUNT_CODE = 'DISCOUNT_CODE',
  WILL_EXPIRE = 'WILL_EXPIRE',
  CANCELLED = 'CANCELLED',
  NO_SUBSCRIPTION = 'NO_SUBSCRIPTION',
  FAILED_RENEWAL = 'FAILED_RENEWAL',
}

export const generateDescription = (entitlement: EntitlementsNode, subscriptionType: SubscriptionEnum) => {
  switch (subscriptionType) {
    case SubscriptionEnum.MONTHLY:
      return `Du har en aktiv månadsprenumeration. ${entitlement.entitledUntil ? 'Nästa debiteringsdatum är ' + formatDate(entitlement.entitledUntil) + '.' : ''}`;
    case SubscriptionEnum.YEARLY:
      return `Du har en aktiv årssprenumeration. ${
        entitlement.entitledUntil ? 'Nästa debiteringsdatum är ' + formatDate(entitlement.entitledUntil) + '.' : ''
      }`;
    case SubscriptionEnum.TEST_PERIOD:
      return `Du har en gratis testperiod till ${
        entitlement.trialUntil && formatDate(entitlement.trialUntil)
      }. Efter testperioden debiteras du för första gången ${
        entitlement.entitledUntil && formatDate(entitlement.entitledUntil)
      }. `;
    case SubscriptionEnum.DISCOUNT_CODE:
      return `Du har en aktiv kampanjkod som gäller till ${entitlement.trialUntil && formatDate(entitlement.trialUntil)}.`;
    case SubscriptionEnum.WILL_EXPIRE:
      return `Om du har en aktiv prenumeration kommer den att förlängas och om du har sagt upp prenumerationen kommer den avslutas.`;
    case SubscriptionEnum.CANCELLED:
      return `Din prenumeration är uppsagd och kommer avslutas ${
        entitlement.entitledUntil && formatDate(entitlement.entitledUntil)
      }. `;
    case SubscriptionEnum.NO_SUBSCRIPTION:
      return 'Vill du ta del av alla våra handplockade filmer? Starta din prenumeration nu.';
    case SubscriptionEnum.FAILED_RENEWAL:
      return 'Vi har inte kunnat dra pengar för din prenumeration, men kommer att testa igen. Dubbelkolla gärna så att dina dina betaluppgifter stämmer.';
    default:
      return ``;
  }
};

export const getBackgroundColor = (subscriptionType: SubscriptionEnum) => {
  switch (subscriptionType) {
    case SubscriptionEnum.MONTHLY:
      return '#1D3447';
    case SubscriptionEnum.YEARLY:
      return '#54211A';
    case SubscriptionEnum.TEST_PERIOD:
      return '#213B2F';
    case SubscriptionEnum.DISCOUNT_CODE:
      return '#3B7AA6';
    case SubscriptionEnum.WILL_EXPIRE:
      return '#424242';
    case SubscriptionEnum.CANCELLED:
      return '#424242';
    case SubscriptionEnum.NO_SUBSCRIPTION:
      return '#8E8E8E';
    case SubscriptionEnum.FAILED_RENEWAL:
      return '#EC6446';
    default:
      return '#213B2F';
  }
};

export const formatPrice = (priceInCents?: number) => `${priceInCents ? (priceInCents / 100).toFixed(2) : 0}`;

export const getPrice = (
  subscriptionInfo: SubscriptionsEdge | undefined,
  entitlement: EntitlementsNode,
  withRecurringPeriod = false
) => {
  return subscriptionInfo
    ? `${formatPrice(subscriptionInfo.node.billing.price.grossPrice)} ${subscriptionInfo.node.billing.price.currency} ${
        withRecurringPeriod ? getRecurringPeriodUnit(entitlement?.offer?.recurringPeriod?.unit) : ''
      }`
    : entitlement.offer.priceInCents
      ? `${formatPrice(entitlement.offer.priceInCents)} SEK${
          withRecurringPeriod ? getRecurringPeriodUnit(entitlement?.offer?.recurringPeriod?.unit) : ''
        }`
      : '';
};

export const getDiscountedPrice = (subscriptionInfo: SubscriptionsEdge | undefined, entitlement: EntitlementsNode) => {
  return subscriptionInfo
    ? `${formatPrice(subscriptionInfo.node.billing.price.netPrice)} ${
        subscriptionInfo.node.billing.price.currency
      } ${getRecurringPeriodUnit(entitlement?.offer?.recurringPeriod?.unit)}`
    : entitlement.offer.priceInCents
      ? `${formatPrice(entitlement.offer.priceInCents)} SEK${getRecurringPeriodUnit(
          entitlement?.offer?.recurringPeriod?.unit
        )}`
      : '';
};

export const generatOfferTitle = (subscriptionType: SubscriptionEnum) => {
  switch (subscriptionType) {
    case SubscriptionEnum.MONTHLY:
      return 'Månadsprenumeration';
    case SubscriptionEnum.YEARLY:
      return 'Årsprenumeration';
    case SubscriptionEnum.TEST_PERIOD:
      return 'Testperiod';
    case SubscriptionEnum.DISCOUNT_CODE:
      return 'Kampanjkod';
    case SubscriptionEnum.WILL_EXPIRE:
      return 'Din prenumeration går ut inom 24 timmar';
    case SubscriptionEnum.CANCELLED:
      return 'Uppsagd';
    case SubscriptionEnum.NO_SUBSCRIPTION:
      return 'Ingen aktiv prenumeration';
    case SubscriptionEnum.FAILED_RENEWAL:
      return 'Betalning gick inte igenom';
    default:
      return ``;
  }
};

export const showSpecialOffer = (specialOffer: ISpecialOffer, subscriptionData: ISubscription) => {
  if (specialOffer?.offerId) {
    const entitlement = subscriptionData?.data.viewer.entitlements.edges.filter(
      (sub) => sub.node.offerId === specialOffer.offerId
    );

    return specialOffer.show ? (entitlement ? entitlement.length > 0 : false) : true;
  } else {
    return false;
  }
};
