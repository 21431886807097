import { FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { Button } from '@/components/Button/Button';

import { LIKE_LEVEL, REASONS } from './CancelSubscriptionSteps.consts';

import { CancelSubscriptionSurveyValues } from '../CancelSubscriptionDialog';
import { ActionButtons, FormWrapper, Headline, Title } from '../CancelSubscriptionDialog.styles';

interface SurveyStepProps {
  onButtonClick: () => void;
  onReactivate: () => void;
  control: Control<CancelSubscriptionSurveyValues>;
  disableOtherReason: boolean;
  finishDate: string;
}

export const SurveyStep: FC<SurveyStepProps> = ({
  onButtonClick,
  onReactivate,
  control,
  disableOtherReason,
  finishDate,
}) => {
  return (
    <form>
      <Title>
        <Headline variant="h3">Vad tråkigt att du lämnar Draken Film.</Headline>
        <Typography>Din prenumeration är uppsagd och avslutas {finishDate}.</Typography>
      </Title>
      <FormWrapper>
        <div>
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} name="reason1" aria-label="reason">
                {REASONS.map(({ label, value }) => (
                  <FormControlLabel key={value} label={label} value={value} control={<Radio color="primary" />} />
                ))}
              </RadioGroup>
            )}
          />
          <Controller
            name="otherReson"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Berätta mer om varför du avslutar din prenumeration:"
                variant="filled"
                color="primary"
                fullWidth
                multiline
                rows={2}
                disabled={disableOtherReason}
              />
            )}
          />
        </div>
        <div>
          <Typography variant="h3">Hur troligt är det att du provar Draken Film igen i framtiden?</Typography>
          <Controller
            name="likeLevel"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} name="likeLevel1" aria-label="likeLevel">
                {LIKE_LEVEL.map(({ label, value }) => (
                  <FormControlLabel key={value} label={label} value={value} control={<Radio color="primary" />} />
                ))}
              </RadioGroup>
            )}
          />
        </div>
      </FormWrapper>
      <ActionButtons>
        <Button color="red" fullWidth onClick={onButtonClick} data-testid="survey-button">
          Skicka svar
        </Button>
        <Button color="red" fullWidth onClick={onReactivate}>
          Jag har ångrat mig, återaktivera min prenumeration.
        </Button>
      </ActionButtons>
    </form>
  );
};
