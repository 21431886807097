export const formatDuration = (duration: number, showSeconds = false): string => {
  const MINUTES_IN_HOUR = 60;
  const SECONDS_IN_HOUR = MINUTES_IN_HOUR * 60;

  const normalize = (n: number) => n.toString().padStart(2, '0');

  let remaining = duration;

  const hours = Math.floor(remaining / SECONDS_IN_HOUR);
  remaining = remaining % SECONDS_IN_HOUR;

  const minutes = Math.floor(remaining / MINUTES_IN_HOUR);
  remaining = remaining % MINUTES_IN_HOUR;

  const seconds = remaining;

  return (
    (hours ? `${normalize(hours)}h ` : '') +
    (minutes ? `${normalize(minutes)}m ` : '') +
    (seconds && showSeconds ? `${normalize(seconds)}s` : '')
  );
};
export const getRecurringPeriodUnit = (unit: 'MONTHLY' | 'YEARLY' | undefined) => {
  if (unit === 'MONTHLY') return '/månad';
  if (unit === 'YEARLY') return '/år';
  else return '';
};
