import { Typography } from '@material-ui/core';
import { FC } from 'react';

import { InfoWithButton } from './InfoWithButton';

interface ReactivationStepProps {
  onButtonClick?: () => void;
  finishDate: string;
}
export const FinishStep: FC<ReactivationStepProps> = ({ onButtonClick, finishDate }) => {
  return (
    <InfoWithButton text="Tack för ditt svar!" buttonText="Stäng" onButtonClick={onButtonClick}>
      <div>
        <Typography>Din prenumeration är uppsagd och avslutas {finishDate || ''}</Typography>
        <Typography>Under uppsägningstiden kan du se film som vanligt.</Typography>
      </div>
    </InfoWithButton>
  );
};
