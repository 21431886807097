import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import { media } from '@/styles';

const COMMON_MARGIN = 6;

export const Content = styled.div`
  text-align: center;
  padding-bottom: ${({ theme }) => theme.spacing(COMMON_MARGIN)}px;

  ${media.md} {
    padding-bottom: 0;
  }
`;

export const ActionButtons = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(4)}px;
  margin-top: ${({ theme }) => theme.spacing(COMMON_MARGIN)}px;
`;

export const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(COMMON_MARGIN)}px;
`;

export const FormWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(COMMON_MARGIN)}px;
`;

export const InfoWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(12)}px;
`;

export const Headline = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
`;

export const DiscountWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin: ${({ theme }) => theme.spacing(0, 0, 4)};
  padding: ${({ theme }) => theme.spacing(2, 3)};
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;
