export const REASONS = [
  {
    label: 'Jag vill byta till en månads/årsprenumeration.',
    value: '0',
    tagName: 'S1-A-byta-prenumeration',
  },
  {
    label: 'Jag hade tekniska problem med Draken Film.',
    value: '1',
    tagName: 'S1-B-tekniska-problem',
  },
  {
    label: 'Jag har bytt till en annan streamingtjänst.',
    value: '2',
    tagName: 'S1-C-annan-streaming',
  },
  {
    label: 'Jag har haft problem med min betalning.',
    value: '3',
    tagName: 'S1-D-betalproblem',
  },
  {
    label: 'Jag hade problem med att ladda ner appen.',
    value: '4',
    tagName: 'S1-E-app-problem',
  },
  {
    label: 'Jag kan inte hitta de filmerna jag vill se.',
    value: '5',
    tagName: 'S1-F-fel-filmer',
  },
  {
    label: 'Draken Film har inga anslutna biografer där jag bor.',
    value: '6',
    tagName: 'S1-G-fel-biografer',
  },
  {
    label: 'Jag har inte råd med Draken Film.',
    value: '7',
    tagName: 'S1-H-inte-rad',
  },
  {
    label: 'Annat (ange nedan).',
    value: '8',
    tagName: 'S1-I-annat',
  },
];

export const LIKE_LEVEL = [
  {
    label: 'Mycket troligt.',
    value: '0',
    tagName: 'S2-A-Mycket-troligt',
  },
  {
    label: 'Troligt.',
    value: '1',
    tagName: 'S2-B-Troligt',
  },
  {
    label: 'Vet ej.',
    value: '2',
    tagName: 'S2-C-Vet-ej',
  },
  {
    label: 'Inte troligt.',
    value: '3',
    tagName: 'S2-D-Inte-troligt',
  },
  {
    label: 'Mycket osannolikt.',
    value: '4',
    tagName: 'S2-E-Mycket-osannolikt',
  },
];
